import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import { SalesOrderStatusSchema } from './SalesOrderStatus';

export const SalesOrderSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    accountId: z.number(),
    createdAt: dateTimeInstant(),
    externalNotes: z.string().nullable(),
    eta: z.string().nullable(),
    legacyId: z.number(),
    manufacturerReference: z.string(),
    internalNotes: z.string().nullable(),
    orderStatusId: z.number(),
    totalCostPrice: z.number(),
    totalFreight: z.number(),
    totalFreightOverride: z.number().nullable(),
    totalSellPrice: z.number(),
    totalTax: z.number(),
    taxValidated: z.boolean().default(true),
    trackingInformation: z.string().nullable(),
    allowDispatch: z.boolean(),

    context: z.object({
        customerOrderGroup: z.object({
            id: z.number(),
            customerDetailsOverride: z.string().nullable(),
            uniqueId: z.string(),
        }),
        customerPurchaseOrder: z.object({
            id: z.number(),
            brandId: z.number().nullable(),
            categoryId: z.number().nullable(),
            splitId: z.number().nullable(),
            purchaseOrderId: z.string(),
            sidemark: z.string(),
            notes: z.string().nullable(),
        }),
        customerConfig: z.object({
            isInternal: z.boolean(),
        }),
        externalSiteContact: z
            .object({
                firstName: z.string(),
                lastName: z.string(),
            })
            .optional(),
        externalSiteBusiness: z
            .object({
                name: z.string(),
            })
            .optional(),
        itemCount: z.number(),
        mainCompany: z.object({
            name: z.string(),
        }),
        status: SalesOrderStatusSchema,
        isArchived: z.boolean(),
    }),
});

export type SalesOrder = z.infer<typeof SalesOrderSchema>;

/**
 * Computed customer display name
 * For external customers this is always the main company name
 * For internal customers it is computed from customerDetailsOverride, business name or contact name
 */
export function getCustomerDisplayName(order: SalesOrder) {
    // external customers always use the main company name
    if (!order.context.customerConfig.isInternal) {
        return order.context.mainCompany.name;
    }

    // for internal customers - try and display the external business or contact name
    let customerName = order.context.customerOrderGroup.customerDetailsOverride ?? undefined;
    if (!customerName) {
        customerName = order.context.externalSiteBusiness?.name;
    }
    if (!customerName) {
        const contact = order.context.externalSiteContact;
        if (contact) {
            customerName =
                `${contact.firstName ?? ''} ${contact.lastName ?? ''}`.trim() || undefined;
        }
    }
    return customerName;
}
