import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum UserDefinedFieldEntityType {
    Attribute = 'ATTRIBUTE',
    Inventory = 'INVENTORY',
    InventoryGroup = 'INVENTORYGROUP',
    InventoryMovement = 'INVENTORYMOVEMENT',
    Location = 'LOCATION',
    PurchaseOrder = 'PURCHASEORDER',
    SalesOrder = 'SALESORDER',
    Schedule = 'SCHEDULE',
    Shipment = 'SHIPMENT',
    Stocktake = 'STOCKTAKE',
    Supplier = 'SUPPLIER',
    Workflow = 'WORKFLOW',
    WorkOrder = 'WORKORDER',
    Workstation = 'WORKSTATION',
    WorkstationGroup = 'WORKSTATIONGROUP',
}
export default UserDefinedFieldEntityType;

const labels: EnumDisplayLabels<typeof UserDefinedFieldEntityType> = {
    Attribute: 'Attribute',
    Inventory: 'Inventory',
    InventoryGroup: 'Inventory Group',
    InventoryMovement: 'Inventory Movement',
    Location: 'Location',
    PurchaseOrder: 'Purchase Order',
    SalesOrder: 'Sales Order',
    Schedule: 'Schedule',
    Shipment: 'Shipment',
    Stocktake: 'Stocktake',
    Supplier: 'Supplier',
    Workflow: 'Workflow',
    WorkOrder: 'Work Order',
    Workstation: 'Workstation',
    WorkstationGroup: 'Workstation Group',
};

export const UserDefinedFieldEntityTypeDisplay = createDisplayEnum(
    UserDefinedFieldEntityType,
    labels,
);
