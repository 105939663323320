import Env from 'config/Env';
import { rest } from 'msw';
import CustomerHoldStatus from './enums/CustomerHoldStatus';
import CustomerPaymentMethod from './enums/CustomerPaymentMethod';
import { fakeBrands, fakeCustomerDetails, getCustomerList } from './faker/CustomersFaker';
import { Brand } from './models/Brand';

const BASE_URL = `${Env.API_BASE_URL}`;

export const customersHandlers = [
    rest.get(`${BASE_URL}/manufacturer/customer`, (req, res, ctx) => {
        const result = getCustomerList();
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    rest.get(`${BASE_URL}/manufacturer/customer/:id`, (req, res, ctx) => {
        const { id } = req.params;
        const customer = fakeCustomerDetails.find(c => c.id === Number(id));
        if (!customer) {
            return res(ctx.status(404));
        }

        return res(ctx.delay(500), ctx.status(200), ctx.json(customer));
    }),

    rest.post(`${BASE_URL}/manufacturer/customer/:id`, (req, res, ctx) => {
        const { id } = req.params;

        const body = req.body as {
            brands: number[];
            holdStatus: CustomerHoldStatus | null;
            notes: string | null;
            isInternal: boolean;
            shippingDetails: string | null;
            taxApplicable: boolean;
            paymentMethod: CustomerPaymentMethod;
            creditLimit: number | null;
            creditTerms: string | null;
            taxExemptions: {
                state: string;
                reason: string;
            }[];
        };

        const customer = fakeCustomerDetails.find(c => c.id === Number(id));
        if (!customer || customer.context.customerConfig === undefined) {
            return res(ctx.status(404));
        }
        customer.context.brands = body.brands
            .map(brandId => fakeBrands.find(b => b.id === brandId))
            .filter(Boolean) as Brand[];
        customer.context.customerConfig.status = body.holdStatus ?? CustomerHoldStatus.None;
        customer.context.customerConfig.notes = body.notes;
        customer.context.customerConfig.isInternal = body.isInternal;
        customer.context.customerConfig.shippingDetails = body.shippingDetails;
        customer.context.customerConfig.taxApplicable = body.taxApplicable;
        customer.context.customerConfig.paymentMethod = body.paymentMethod;
        customer.context.customerConfig.creditLimit = body.creditLimit;
        customer.context.customerConfig.creditTerms = body.creditTerms;
        customer.context.customerConfig.taxExemptions = body.taxExemptions;

        return res(ctx.delay(500), ctx.status(200), ctx.json(customer));
    }),

    rest.get(`${BASE_URL}/brand`, (req, res, ctx) => {
        return res(ctx.delay(500), ctx.status(200), ctx.json(fakeBrands));
    }),
];
